<template>
    <my-dialog
        v-model="dialogVisible"
        width="1090px"
        title="选择保函种类"
        @close="close"
        :showclose="showclose"
    >
        <el-row>
            <el-col :span="24">
                <el-row>
                    <el-col :span="24"><b>保函类型</b></el-col>
                    <el-col
                        :span="4"
                        v-for="(item, index) in bhlxList"
                        :key="index"
                    >
                        <div
                            class="applybh-nav"
                            @click="selectOK(item, index)"
                            :class="[
                                bhtype.bhlx === item.valueid ? 'on' : 'off',
                            ]"
                            :style="{
                                color:
                                    bhtype.bhlx === item.valueid
                                        ? webInfo.defcolor
                                        : '',
                                borderColor:
                                    bhtype.bhlx === item.valueid
                                        ? webInfo.defcolor
                                        : '',
                            }"
                        >
                            <span class="apply-nav_img-con">
                                <img
                                    :src="
                                        ['HomeMancheng', 'HomeFybh'].includes(
                                            webInfo.webStyle
                                        )
                                            ? `/images/ggzyApply/${item.valueid}.png`
                                            : `/images/applybh/${item.valueid}.png`
                                    "
                                />
                            </span>
                            <p>{{ item.valuename }}</p>
                        </div>
                    </el-col>
                </el-row>
            </el-col>
            <el-col :span="24">
                <el-row>
                    <el-col :span="24"><b>出函机构</b></el-col>
                    <el-col
                        :span="4"
                        v-for="(item, index) in chjgList"
                        :key="index"
                    >
                        <div
                            class="applybh-nav"
                            @click="selectOK(item, index)"
                            :class="[
                                bhtype.chjg === item.valueid ? 'on' : 'off',
                            ]"
                            :style="{
                                color:
                                    bhtype.chjg === item.valueid
                                        ? webInfo.defcolor
                                        : '',
                                borderColor:
                                    bhtype.chjg === item.valueid
                                        ? webInfo.defcolor
                                        : '',
                            }"
                        >
                            <span class="apply-nav_img-con">
                                <img
                                    :src="
                                        ['HomeMancheng', 'HomeFybh'].includes(
                                            webInfo.webStyle
                                        )
                                            ? `/images/ggzyApply/${item.valueid}.png`
                                            : `/images/applybh/${item.valueid}.png`
                                    "
                                />
                            </span>
                            <p>{{ item.valuename }}</p>
                        </div>
                    </el-col>
                </el-row>
            </el-col>
            <el-col :span="24" v-if="projectSourceIsShow">
                <el-row>
                    <el-col :span="24"><b>项目来源</b></el-col>
                    <el-col :span="24" style="margin: 18px 0 18px 15px">
                        <el-radio-group v-model="bhtype.projectSource">
                            <el-radio
                                :label="item.label"
                                v-for="(item, index) in projectSourceList"
                                :key="index"
                            >
                                {{ item.label }}
                            </el-radio>
                        </el-radio-group>
                    </el-col>
                </el-row>
            </el-col>
            <el-col
                :span="24"
                v-if="bhtype.bhlx == 'lybh' && bhtype.chjg == 'dbjg'"
            >
                <el-row>
                    <el-col :span="24"><b>担保公司</b></el-col>
                    <el-col :span="24" style="margin: 18px 0 18px 15px">
                        <el-radio-group v-model="bhtype.sfrz">
                            <el-radio
                                :label="item.key"
                                v-for="(item, index) in sfrzList"
                                :key="index"
                            >
                                {{ item.value }}
                            </el-radio>
                        </el-radio-group>
                        <span
                            :style="{
                                marginLeft: '18px',
                                color: webInfo.defcolor,
                            }"
                        >
                            (根据文件要求选择，如无特殊要求可不选择)
                        </span>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
        <div style="text-align: center">
            <div style="text-align: left">
                <b>预计出函时间:</b>
                <span :style="{ color: webInfo.defcolor, fontWeight: 'bold' }">
                    {{ expectTime }}
                </span>
            </div>

            <el-button @click="selectBhType" type="primary" size="large"
                >确定</el-button
            >

            <el-dialog
                width="500px"
                title="出函机构"
                :visible.sync="vendorVisible"
                append-to-body
            >
                <el-form :model="bxjgForm" ref="bxjgForm" v-if="vendorVisible">
                    <el-form-item
                        label="请选择项目所在城市"
                        prop="procitycode"
                        :rules="[
                            {
                                required: true,
                                message: '请选择项目所在城市',
                                trigger: ['blur'],
                            },
                        ]"
                    >
                        <city-cascader
                            v-model="bxjgForm.procitycode"
                            :cityname.sync="bxjgForm.procityname"
                            :change-on-select="false"
                            city-only
                            @change="bxCityChange"
                            :placeholder="'请选择项目所在城市'"
                            style="width: 100%"
                        ></city-cascader>
                    </el-form-item>

                    <el-form-item
                        label="担保公司"
                        v-show="bxjgForm.procitycode"
                    >
                        <div class="vendor-sel-con">
                            <div
                                class="item"
                                :class="{ action: index === curindex }"
                                :style="{
                                    color:
                                        index === curindex
                                            ? webInfo.defcolor
                                            : '',
                                    borderColor:
                                        index === curindex
                                            ? webInfo.defcolor
                                            : '',
                                }"
                                v-for="(item, index) in vendorList"
                                :key="index"
                                @click="selectVendor(item, index)"
                            >
                                {{ item.vendorname }}
                            </div>
                        </div>
                    </el-form-item>

                    <div style="text-align: center; margin-top: 50px">
                        <el-button type="primary" @click="confirmVendor">
                            确定
                        </el-button>
                    </div>
                </el-form>
            </el-dialog>
        </div>
    </my-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
    inject: ['webInfo'],
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        index: {
            index: Boolean,
            default: false,
        },
        showclose: {
            type: Boolean,
            default: true,
        },
        ggzyUrlParameter: {
            type: String,
            default: '',
        },

        isIndexApply: {
            type: Boolean,
            default: false,
        },

        indexGuaranteItem: {
            type: Object,
            default () {
                return {};
            },
        },
    },
    data () {
        return {
            curindex: 0,
            vendorVisible: false,
            curVendorinfo: {},
            vendorList: [],
            expectTime: '-',
            bhtype: {
                chjg: '',
                bhlx: '',
                sfrz: '',
                projectSource: '',
            },
            bhTypeName: {
                chjg: '',
                bhlx: '',
            },

            bhlxList: [],
            chjgList: [],

            sfrzList: [
                { value: '融资性担保公司', key: '1' },
                { value: '非融资性担保公司', key: '0' },
            ],

            projectSourceList: [
                { label: '惠招标电子招投标交易平台' },
                { label: '其他平台' },
            ],

            bxjgForm: {},
        };
    },
    computed: {
        ...mapGetters({
            isLogined: 'userinfo/loginStatus',
        }),
        dialogVisible: {
            get () {
                return this.value;
            },
            set (val) {
                this.$emit('input', val);
            },
        },

        sfrzIsShow () {
            if (this.bhtype.bhlx === 'lybh' && this.bhtype.chjg === 'dbjg') {
                return true;
            } else {
                return false;
            }
        },

        projectSourceIsShow () {
            if (['dbjg', 'yhjg'].indexOf(this.bhtype.chjg) > -1) {
                return false;
            } else {
                return false;
            }
        },
    },
    watch: {
        'bhtype.chjg': {
            handler (n, o) {
                if (n == 'yhjg' && this.bhtype.bhlx == 'lybh') {
                    this.expectTime = '3-4个工作日';
                } else if (n == 'yhjg' && this.bhtype.bhlx == 'tbbh') {
                    this.expectTime = '2个工作日';
                } else {
                    this.expectTime = '1个工作日';
                }
            },
        },
        'bhtype.bhlx': {
            handler (n, o) {
                if (n == 'lybh' && this.bhtype.chjg == 'yhjg') {
                    this.expectTime = '3-4个工作日';
                } else if (n == 'tbbh' && this.bhtype.chjg == 'yhjg') {
                    this.expectTime = '2个工作日';
                } else {
                    this.expectTime = '1个工作日';
                }
            },
        },

        sfrzIsShow (n, o) {
            if (n === false) {
                this.$set(this.bhtype, 'sfrz', null);
                //清除履约保函数据
                if (window.sessionStorage.getItem('sfrz')) {
                    window.sessionStorage.removeItem('sfrz');
                }
            }
        },

        projectSourceIsShow (n, o) {
            if (n === false) {
                this.bhtype.projectSource = undefined;
            }
        },

        dialogVisible: {
            handler(n, o) {
                if (n === false) {
                    // 关闭
                    return;
                }

                // 打开
                this.$set(this.bhtype, 'sfrz', null);

                if (this.indexGuaranteItem.key && this.isIndexApply) {
                    this.$set(this.bhtype, 'bhlx', this.indexGuaranteItem.key);

                    this.$set(
                        this.bhTypeName,
                        'bhlx',
                        this.indexGuaranteItem.label
                    );
                }
            },
            deep: true,
        },

        vendorVisible: {
            handler (n, o) {
                if (n === true) {
                    this.vendorList = [];
                    this.curVendorinfo = {};
                    this.$set(this.bxjgForm, 'procitycode', '');
                    this.$set(this.bxjgForm, 'procityname', '');
                }
            },
            deep: true,
        },
    },
    methods: {
        // 选中出函机构
        selectVendor (item, index) {
            this.curindex = index;
            this.curVendorinfo = item;
        },
        // 选定出函机构
        confirmVendor () {
            this.$refs['bxjgForm'].validate((valid) => {
                if (!valid) return;
            });

            const switchObj = {
                    // 天安保险
                    E000000tianan: () => {
                        const url = `${this.curVendorinfo.vendorurl}/web/guarantee/tianan/form.html`;

                        window.location.assign(url);
                    },
                    // 中华保险
                    E000000zhonghua: () => {
                        const url = `${this.curVendorinfo.vendorurl}/web/guarantee/zhonghua/form-xm.html${this.ggzyUrlParameter}`;

                        window.location.assign(url);
                    },
                    E000095146: () => {
                        const url = `${this.curVendorinfo.vendorurl}/web/guarantee/hanhua/form.html`,
                            search = toSearch({
                                partnercode: this.curVendorinfo.vendorid,
                                partnername: this.curVendorinfo.vendorname,
                            });

                        window.location.assign(`${url}${search}`);
                    },
                    E0000002501: () => {
                        const url = `${this.curVendorinfo.vendorurl}/web/all/all.html`,
                            search = toSearch({
                                chjg: 'bxjg',
                                bhlx: 'tbbh',
                            });

                        window.location.assign(`${url}${search}`);
                    },
                    E0000001381: () => {
                        // 阳光保险集团股份有限公司-保证保险
                        // 系统内申请：省外跳转保函通申请页面，否则跳转阳光保险申请页面
                        if (this.bxjgForm.procitycode.slice(0, 2) !== '13') {
                            //保函通
                            let url,
                                query = {
                                    procitycode: this.bxjgForm.procitycode,
                                    procityname: this.bxjgForm.procityname,
                                    bhlx: 'tbbh',
                                    chjg: 'bxjg',
                                    vendorid: this.curVendorinfo.vendorid,
                                    vendorname: this.curVendorinfo.vendorname,
                                    vendorurl: this.curVendorinfo.vendorurl,
                                };

                            if (this.isLogined) {
                                url = '/sys/all/all.html';
                            } else {
                                url = '/web/all/all.html';
                            }

                            this.goto({
                                path: url,
                                query,
                            });
                        } else {
                            //阳光保险
                            let url = `${this.curVendorinfo.vendorurl}/web/guarantee/yangguang/form.html${this.ggzyUrlParameter}`;

                            window.location.assign(url);
                        }
                    },
                    // 黄河保险
                    E20240105093444: () => {
                        const url = `${this.curVendorinfo.vendorurl}/web/guarantee/huanghe/form.html${this.ggzyUrlParameter}`;

                        window.location.assign(url);
                    },
                },
                handler = switchObj[this.curVendorinfo.vendorid];

            handler && handler();
        },
        //获取字典中的字段
        queryDict (dictName, variableName) {
            let storedList = sessionStorage.getItem(variableName),
                parsedList;

            if (storedList) {
                try {
                    parsedList = JSON.parse(storedList);
                } catch (e) {
                    parsedList = [];
                }
            }

            if (parsedList && parsedList.length) {
                this[variableName] = parsedList;

                return;
            }

            this.$post(
                `${this.$store.state.api.baseUrl}/commonreq/getdictinfo.json`,
                { typecode: dictName },
                (data) => {
                    this[variableName] = data;
                }
            );
        },
        // 选中保函类型、出函机构
        selectOK (item, index) {
            this.$set(this.bhtype, item.typecode, item.valueid);
            this.$set(this.bhTypeName, item.typecode, item.valuename);
        },
        // 选择保函种类弹窗关闭
        close () {
            this.bhtype = {};
        },
        // 选定保函类型、出函机构
        selectBhType () {
            // 选中保函

            new Chain()
                .link((next) => {
                    // 保函通 平台 需判断是否 实名认证
                    if(this.$store.state.webInfo.id == 'bht'){
                        this.$post(
                            `${this.$store.state.api.baseUrl}/usermg/loginuser.json`,
                            function (data) {
                                if(data.realStatus == '1'){
                                    next();
                                } else{
                                    ShowConfirm('申请保函之前请先进行实名认证', 'info', () => {
                                        this.goto('/sys/userinfo/list.html');
                                    });

                                    return;
                                }
                            }
                        );
                    }else{
                        next();
                    }
                })
                .link((next) => {
                    // 验证必填项
                    if (!this.bhtype.bhlx) {
                        this.$message({
                            message: '保函类型不能为空',
                            type: 'warning',
                        });

                        return;
                    }

                    if (!this.bhtype.chjg) {
                        this.$message({
                            message: '出函机构不能为空',
                            type: 'warning',
                        });

                        return;
                    }

                    if (
                        this.projectSourceIsShow &&
                        !this.bhtype.projectSource
                    ) {
                        this.$message({
                            message: '项目来源不能为空',
                            type: 'warning',
                        });

                        return;
                    }

                    next();
                })
                .link((next) => {
                    // 提示保函类型

                    this.$confirm(
                        `您选择的是由
                        <span style="color:${this.webInfo.defcolor}">
                            ${this.bhTypeName.chjg}
                        </span>
                        出具的
                        <span style="color:${this.webInfo.defcolor}">
                            ${this.bhTypeName.bhlx}
                        </span>`,
                        '提示',
                        {
                            dangerouslyUseHTMLString: true,
                            confirmButtonText: '确认办理',
                            cancelButtonText: '重新选择',
                            type: 'info',
                            callback: (action, instance) => {
                                if (action === 'confirm') {
                                    next();
                                }
                            },
                        }
                    );
                })
                .link((next) => {
                    // 校验可否办理
                    this.$post(
                        `${this.$store.state.api.baseUrl}/commonreq/bhbltd.json`,
                        this.bhtype,
                        (data) => {
                            if (!data) {
                                //如没有有办理通道
                                this.$message('暂未开通此保函类型办理通道');

                                return;
                            }

                            next();
                        }
                    );
                })
                .link((next) => {
                    // 根据参数进行跳转

                    this.jumpHandler();
                })
                .run();
        },
        // 根据选中、设置的参数进行跳转
        jumpHandler() {
            if (
                this.bhtype.chjg == 'bxjg' &&
                this.bhtype.bhlx == 'tbbh'
            ) {
                //跳转保险界面
                sessionStorage.setItem('chjg', this.bhtype.chjg);
                sessionStorage.setItem('bhlx', this.bhtype.bhlx);

                this.vendorVisible = true;

                return;
            }

            //非保险界面
            sessionStorage.setItem('chjg', this.bhtype.chjg);
            sessionStorage.setItem('bhlx', this.bhtype.bhlx);

            if (this.bhtype.sfrz) {
                sessionStorage.setItem('sfrz', this.bhtype.sfrz);
            }

            if (this.getQuery('vtype') === 'hzb') {
                //惠招标跳转
                let dwcode = this.getQuery('dwcode'),
                    bdcode = this.getQuery('bdcode');

                this.goto({
                    path: '/web/all/all.html',
                    query: {
                        ordersrc: 'hzb',
                        dwcode,
                        bdcode,
                    },
                });

                return;
            }

            if (this.ggzyUrlParameter) {
                this.goto(`/sys/all/all.html${this.ggzyUrlParameter}`);

                return;
            }

            if (this.projectSourceIsShow && this.bhtype.projectSource) {
                this.goto({
                    path: '/sys/all/all.html',
                    query: {
                        projectSource: this.bhtype.projectSource,
                    },
                });

                return;
            }

            if (this.isIndexApply) {
                this.goto('/web/all/all.html');

                return;
            }

            if (this.$route.path === '/sys/all/all.html') {
                window.location.reload();

                return;
            }

            this.goto('/sys/all/all.html');
        },

        // 选中出函机构-所在城市
        bxCityChange(val) {
            var that = this;
            this.$refs['bxjgForm'].validateField('procitycode');
            this.$post(
                `${this.$store.state.api.baseUrl}/commonreq/vendordeal.json`,
                {
                    //担保公司信息
                    guaranteetype: this.bhtype.bhlx,
                    agencytype: this.bhtype.chjg,
                    ordersrc: 'bht',
                    procitycode: this.bxjgForm.procitycode,
                },
                function (data) {
                    that.vendorList = data;
                    that.curindex = 0;
                    that.curVendorinfo = data[0];
                }
            );
        },
    },
    mounted() {
        this.queryDict('bhlx', 'bhlxList');
        this.queryDict('chjg', 'chjgList');
    },
};
</script>

<style lang="scss" scoped>
.applybh-nav {
    text-align: center;
    cursor: pointer;
    padding: 1em 0;
    margin: 1em;
    box-shadow: 0 0 30px #efefef;

    &:hover {
        .apply-nav_img-con {
            background: #f7f6ef;

            img {
                width: 80%;
            }
        }

        p {
        }
    }

    /*选择保函选中项样式*/
    &.on {
        border: 1px solid;
        box-sizing: border-box;
        margin: 13px;
    }

    &.off {
        border: none;
    }

    * {
        transition: all 0.3s;
    }

    .apply-nav_img-con {
        width: 80px;
        height: 80px;
        display: inline-block;
        border-radius: 50%;
        position: relative;

        img {
            width: 85%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    p {
        font-size: 16px;
        line-height: 1em;
        font-weight: bold;
    }
}

.vendor-sel-con {
    margin-top: 40px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .item {
        padding: 5px;
        border: 1px dashed gray;
        margin-right: 6px;
        width: 48%;
        margin: 6px 0;
        cursor: pointer;

        &:hover {
        }

        &.action {
            padding: 5px;
            border: 2px solid;
        }
    }
}
</style>